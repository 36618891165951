import styled from 'styled-components'
import theme from 'styles/theme'
import easing from 'styles/easing'
import {
  Appear,
  Wrapper as SharedWrapper,
  Header as SharedHeader,
  Body as SharedBody,
} from './SharedComponents.style'

export const Wrapper = styled(SharedWrapper)`
  animation: ${Appear} 0.5s ${easing.easeOutQuart};
  background-color: ${theme.colors.coaldark};
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`

export const Header = styled(SharedHeader)`
  flex: none;
`

export const Body = styled(SharedBody)`
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
`
