/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import styled from 'styled-components'
import { display } from 'styled-system'
import { Heading as BaseHeading, Text as defaultText } from 'rebass'
import { textTransform, verticalAlign } from 'styles/sharedStyle'
import theme from 'styles/theme'

const {
  title100,
  title70,
  title60,
  title42,
  title32,
  title26,
  title20,
  title20Italics,
  title16,
  body20,
  body18,
  body16,
  body14,
  body12,
  body12Uppercase,
  body12UppercaseCondensed,
  body10,
  body10Uppercase,
  body8Uppercase,
  body8,
  label,
} = theme.textStyles

const BaseText = styled(defaultText)`
  ${verticalAlign}
  ${textTransform}
  ${display}
`

export const Title100 = (props) => (
  <BaseText as="span" {...title100} {...props}>
    {props.children}
  </BaseText>
)

export const Title70 = (props) => (
  <BaseHeading as="h1" {...title70} {...props}>
    {props.children}
  </BaseHeading>
)

export const Title60 = (props) => (
  <BaseHeading as="h2" {...title60} {...props}>
    {props.children}
  </BaseHeading>
)

export const Title42 = (props) => (
  <BaseHeading as="h3" {...title42} {...props}>
    {props.children}
  </BaseHeading>
)

export const Title32 = (props) => (
  <BaseHeading as="h4" {...title32} {...props}>
    {props.children}
  </BaseHeading>
)

export const Title26 = (props) => (
  <BaseHeading as="h5" {...title26} {...props}>
    {props.children}
  </BaseHeading>
)

export const Title20 = (props) => (
  <BaseHeading as="h6" {...title20} {...props}>
    {props.children}
  </BaseHeading>
)

export const Title16 = (props) => (
  <BaseHeading as="h7" {...title16} {...props}>
    {props.children}
  </BaseHeading>
)

export const Standfirst = (props) => (
  <BaseText as="p" {...title20Italics} {...props}>
    {props.children}
  </BaseText>
)

export const Body20 = (props) => (
  <BaseText as="p" {...body20} {...props}>
    {props.children}
  </BaseText>
)

export const Body18 = (props) => (
  <BaseText as="p" {...body18} {...props}>
    {props.children}
  </BaseText>
)

export const Body16 = (props) => (
  <BaseText as="p" {...body16} {...props}>
    {props.children}
  </BaseText>
)

export const Body14 = (props) => (
  <BaseText as="p" {...body14} {...props}>
    {props.children}
  </BaseText>
)

export const Body14Div = (props) => (
  <BaseText as="div" {...body14} {...props}>
    {props.children}
  </BaseText>
)

export const Body12 = ({ uppercase = false, ...rest }) => {
  const font = uppercase ? body12Uppercase : body12
  return (
    <BaseText as="p" {...font} {...rest}>
      {rest.children}
    </BaseText>
  )
}

export const Body10 = ({ uppercase = false, ...rest }) => {
  const font = uppercase ? body10Uppercase : body10
  return (
    <BaseText as="p" {...font} {...rest}>
      {rest.children}
    </BaseText>
  )
}

export const Body8 = ({ uppercase = false, ...rest }) => {
  const font = uppercase ? body8Uppercase : body8
  return (
    <BaseText as="p" {...font} {...rest}>
      {rest.children}
    </BaseText>
  )
}

export const CtaLabel = (props) => (
  <BaseText as="span" {...body12Uppercase} {...props}>
    {props.children}
  </BaseText>
)

export const Eyebrow = (props) => {
  const textProps = props.isMobile ? body8Uppercase : body10Uppercase
  return (
    <BaseText as="p" {...textProps} {...props}>
      {props.children}
    </BaseText>
  )
}

export const EyebrowCondensed = (props) => (
  <BaseText as="p" {...body12UppercaseCondensed} {...props}>
    {props.children}
  </BaseText>
)

export const Label = (props) => (
  <BaseText
    as="p"
    {...{
      ...label,
      ...props,
    }}>
    {props.children}
  </BaseText>
)

export const Divider = styled.span`
  display: inline-block;
  margin: -1px ${theme.space[1]} 0;
`

export const DangerLabel = (props) => (
  <BaseText
    as="p"
    {...{
      ...body10Uppercase,
      ...props,
    }}
    style={{
      color: theme.colors.red,
    }}>
    <span
      style={{
        display: 'inline-block',
        fontSize: '14px',
        margin: `-1px ${theme.space[1]} 0`,
      }}>
      ⬩
    </span>
    {props.children}
  </BaseText>
)

export const FormInput = (props) => (
  <BaseText as="input" {...body14} {...props}>
    {props.children}
  </BaseText>
)

export const FormDropdown = (props) => (
  <BaseText as="select" {...body14} {...props}>
    {props.children}
  </BaseText>
)

export const FormInputTextarea = (props) => (
  <BaseText as="textarea" {...body14} {...props}>
    {props.children}
  </BaseText>
)
