import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Favicon = ({ content }) => {
  let favicon = '/favicon.ico'

  if (content) {
    const svg = content.trim()
    favicon =
      typeof window === 'undefined'
        ? `data:image/svg+xml;base64,${Buffer.from(svg).toString('base64')}`
        : `data:image/svg+xml;base64,${btoa(svg)}`
  }

  return (
    <Helmet>
      <link id="icon-favicon" rel="icon" href={favicon} type="image/x-icon" />
    </Helmet>
  )
}

Favicon.defaultProps = {
  content: '',
}

Favicon.propTypes = {
  content: PropTypes.string,
}

export default Favicon
