import React from 'react'
import PropTypes from 'prop-types'
import Share from './Share'
import { Wrapper, Header, Body } from './ShareModal.style'

const ShareModal = ({
  closeUrl,
  bodyCopy,
  ctaLabel,
  headline,
  ...shareProps
}) => (
  <Wrapper>
    <Header closeUrl={closeUrl} />
    <Body>
      <Share
        bodyCopy={bodyCopy}
        ctaLabel={ctaLabel}
        headline={headline}
        {...shareProps}
        colorScheme="dark"
        showPinterest={false}
      />
    </Body>
  </Wrapper>
)

ShareModal.propTypes = {
  bodyCopy: PropTypes.string,
  closeUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  headline: PropTypes.string,
}

ShareModal.defaultProps = {
  bodyCopy: '',
  closeUrl: '',
  ctaLabel: '',
  headline: '',
}

export default ShareModal
