import styled, { keyframes } from 'styled-components'
import easing from 'styles/easing'
import theme from 'styles/theme'
import { Body16, Eyebrow, Title60 } from 'components/Typography'
import { rem } from 'polished'

// Keyframes para animações
export const Appear = keyframes`
  0% {
    opacity: 0;
    transform: scale(.95);
  }
`

// Estilos compartilhados
export const Wrapper = styled.div`
  animation: ${Appear} 0.5s ${easing.easeOutQuart};
  background-color: ${theme.colors.coaldark};
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`

export const Header = styled.div`
  flex: none;
`

export const Body = styled(Body16)`
  font-size: ${rem(16)};
  margin: 1em 0;
`

export const Headline = styled(Title60)`
  color: inherit;
  font-size: ${rem(42)};
  letter-spacing: -0.05em;
  margin: 0 auto 0.5em;
  max-width: 500px;

  ${theme.mediaQueries.medium} {
    font-size: ${rem(60)};
  }
`

export const Prompt = styled(Eyebrow)`
  margin: 40px 0 12px;
`
